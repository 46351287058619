import "./AboutPage.scss";
import { Header } from "../../../Components/Views/Header/Header";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import { FormattedMessage, useIntl } from "react-intl";

const AboutPage = () => {
  const i18n = useIntl();

  return (
    <Header page="ParcoursArty" backAction={getNavigationService().value().goBack}>
      <div className="AboutPage-root">
        <h1 className="AboutPage-title">
          <FormattedMessage id="AboutPage_Title" />
        </h1>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text1" />
        </p>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text2" />
        </p>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text3" />
        </p>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text4" />
        </p>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text5" />
          <br />
          <FormattedMessage id="AboutPage_Text5_1" />
          <br />
          <FormattedMessage id="AboutPage_Text5_2" />
          <br />
          <FormattedMessage id="AboutPage_Text5_3" />
        </p>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text6" />
          <a href={i18n.formatMessage({ id: "AboutPage_Text6_HttpLink" })}>
            <FormattedMessage id="AboutPage_Text6_Link" />
          </a>
        </p>
      </div>
    </Header>
  );
};

export { AboutPage };
