import "./Header.scss";
import { ButtonBase, IconButton } from "@material-ui/core";
import { Icons } from "../Icons/Icons";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import ParcoursCanalLogo from "../../../assets/images/img_parcours_canal_logo.svg";
import ParcoursMerLogo from "../../../assets/images/img_parcours_mer_logo.svg";
import Logo from "../../../assets/images/img_parcours_arty_logo.png";
import { Parcours } from "../../../../Core/Models/Section/Section";

export interface HeaderProps {
  page?: Parcours;
  children: React.ReactNode;
  backAction?: () => void;
  closeAction?: () => void;
  renderCustomAction?: React.ReactNode;
  customAction?: () => void;
}

const Header = ({ page, children, backAction, closeAction, renderCustomAction, customAction }: HeaderProps) => {
  return (
    <div className="Header-root">
      <div className={page === "ParcoursArty" ? "Header-header ParcoursArty-Header" : "Header-header"}>
        {backAction ? (
          <IconButton onClick={backAction} className="Header-back">
            {Icons.Left}
          </IconButton>
        ) : (
          <div className="Header-back" />
        )}
        <ButtonBase onClick={getNavigationService().value().goToHome}>
          {page && <img src={page === 'ParcoursMer' ? ParcoursMerLogo : page === 'ParcoursCanal' ? ParcoursCanalLogo : Logo} alt="logo" className="Header-logo" />}
        </ButtonBase>
        {closeAction ? (
          <IconButton onClick={closeAction} className="Header-close">
            {Icons.CloseWhite}
          </IconButton>
        ) : renderCustomAction ? (
          <IconButton onClick={customAction} className="Header-close">
            {renderCustomAction}
          </IconButton>
        ) : (
          <div className="Header-close" />
        )}
      </div>
      {window.location.href.endsWith("artwork") && (
        <div className="Header-border-root">
          <div className="Header-border" />
        </div>
      )}
      <div className="Header-children">{children}</div>
    </div>
  );
};

export { Header };
