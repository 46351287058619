import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import { Header } from "../../../Components/Views/Header/Header";
import "./ArtWorkMapPage.scss";
import { Map } from "../../../Components/Views/Map/Map";
import { useArtWorkMapPageController } from "./ArtWorkMapPageController";
import { Parcours } from "../../../../Core/Models/Section/Section";

const ArtWorkMapPage = () => {
  const { state, sectionId } = useArtWorkMapPageController();

  return (
    <Header page={state.sectionType as Parcours} backAction={() => getNavigationService().value().goBack()}>
      <div className="ArtWorkMapPage-root">
        {!state.busy && (
          <Map
            markersType={state.sectionType}
            markers={state.items}
            onClick={(artWorkId: string) => getNavigationService().value().goToArtWorkDetails(sectionId, artWorkId)}
            currentPosition={state.currentPosition}
          />
        )}
      </div>
    </Header>
  );
};

export { ArtWorkMapPage };
