import { useEffect, useState } from "react";
import { CustomDialog } from "../../Components/Views/CustomDialogs/CustomDialog/CustomDialog";
import Cookies from "js-cookie";
import "./NewsDialog.scss";
import { News } from "../../../Core/Models/News";
import { IoC } from "../../../Core/Services/ServicesContainer";
import { INewsDataService, NewsDataServiceName } from "../../../Core/DataServices/NewsDataService";
import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import NoImage from "../../assets/images/img_artwork_canal_no_image.svg";

export interface NewsDialogProps {
  sectionNews?: News;
}

const useNewsDialog = ({ sectionNews }: NewsDialogProps) => {
  const [news, setNews] = useState<News>();
  useEffect(() => {
    const canceler = new XHRRequestCanceler();

    (async () => {
      try {
        let nResponse;
        if (window.location.href.indexOf("artwork") === -1) {
          nResponse = await IoC.get<INewsDataService>(NewsDataServiceName).getNews(canceler);
        }
        if (nResponse?.is_success) {
          const cookie = Cookies.get("news");
          if (!cookie?.includes(nResponse.data.id)) {
            if (!canceler.isCanceled) {
              setNews(nResponse.data);
            }
          }
        } else if (sectionNews) {
          const cookie = Cookies.get("news");
          if (!cookie?.includes(sectionNews.id)) {
            setNews(sectionNews);
          }
        }
      } catch {
        // do nothing
      }
    })();

    return () => {
      canceler.cancel();
    };
  }, [sectionNews]);

  const markAsRead = (n: News) => {
    let cookie = Cookies.get("news");
    let value;
    if (cookie?.includes(n.id)) {
      setNews(undefined);
    } else {
      if (cookie) {
        value = JSON.parse(cookie);
        let last = Object.keys(value)[Object.keys(value).length - 1];
        let lastVal = parseInt(last) + 1;
        value[lastVal] = n.id;
      } else {
        value = {
          1: n.id,
        };
      }
      Cookies.set("news", JSON.stringify(value));
      setNews(undefined);
    }
  };

  return { news, markAsRead };
};

export const NewsDialog = (sectionNews: NewsDialogProps) => {
  const { news, markAsRead } = useNewsDialog(sectionNews);
  const onClose = () => {
    news && markAsRead(news);
  };

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <CustomDialog
      open={Boolean(news)}
      onClose={onClose}
      renderActions={() => <></>}
      fullscreen
      classes={{ paper: "NewsDialog-root" }}>
      {news && (
        <div className="NewsDialog-dialogContent-root">
          <h3>{news.title}</h3>
          <div className="NewsDialog-dialogContent-picture">
            {news.picture_url && (
              <img
                src={news.picture_url}
                alt=""
                className="NewsDialog-dialogContent-urlImage"
                onLoad={() => {
                  setIsLoaded(true);
                }}
                style={isLoaded ? { opacity: 1 } : { opacity: 0 }}
              />
            )}
            <img
              src={NoImage}
              alt="artwork"
              style={isLoaded ? { opacity: 0 } : {}}
              className="NewsDialog-dialogContent-fallbackImage"
            />
          </div>
          {/* <img src={news.picture_url} alt="" /> */}
          <span className="description">{news.content}</span>
        </div>
      )}
    </CustomDialog>
  );
};
