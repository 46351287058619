import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { IArtWorkDataService, ArtWorkDataServiceName } from "../../DataServices/ArtWorkDataService";
import { ArtWorkDetails } from "../../Models/ArtWork/ArtWork";
import { IoC } from "../../Services/ServicesContainer";
import { BaseAppViewModel } from "../Base/BaseAppViewModel";
import { BaseViewState } from "../Base/BaseViewModel";

export interface ArtWorkDetailsViewState extends BaseViewState {
  sectionName: string;
  search: string;
  item: ArtWorkDetails;
}

const InitialArtWorkDetailsViewState: ArtWorkDetailsViewState = {
  sectionName: "",
  search: "",
  item: {
    app_url: "",
    ar_file_id: "",
    ar_file_url: "",
    artist_name: "",
    categories: [],
    country: "",
    description: "",
    id: "",
    is_outside: false,
    latitude: 0,
    longitude: 0,
    picture_url: "",
    section: {
      id: "",
      image_url: "",
      name: "",
      type: "",
      news: {
        id: "",
        content: "",
        title: "",
      },
    },
    subtitle: "",
    title: "",
    year: "",
    short_description: "",
    copyrights: "",
  },
};

export class ArtWorkDetailsViewModel extends BaseAppViewModel<ArtWorkDetailsViewState> {
  private readonly ArtWorkDataService: IArtWorkDataService;

  constructor(public readonly sectionId: string, private readonly artworkId: string) {
    super(InitialArtWorkDetailsViewState);
    this.ArtWorkDataService = IoC.get<IArtWorkDataService>(ArtWorkDataServiceName);
    this.getItems();
  }

  protected getItems = () => {
    this.WrapError(async (cts: XHRRequestCanceler) => {
      const response = await this.ArtWorkDataService.getArtWork(this.artworkId, cts);

      let desc = "";

      if (response.data.description) {
        desc = response.data.description;
        desc.replaceAll("&amp;", "&");

        const regex = /<video[^>]+><\/video>/g;
        const regex2 = /"([^"]*)"/g;

        let matches: RegExpExecArray | null;
        let matches2: RegExpExecArray | null;

        while ((matches = regex.exec(desc)) !== null) {
          const videoTag = matches[0];

          while ((matches2 = regex2.exec(videoTag)) !== null) {
            const videoSrc = matches2[1].replaceAll("&amp;", "&");

            desc = desc.replace(videoTag, `<video src="${videoSrc}" type={video/*} controls></video>`);
          }
        }
      }

      this.setState({
        ...this.state,
        item: {
          ...response.data,
          description: desc,
        },
      });
    });
  };

  public onChangeField = (field: keyof ArtWorkDetailsViewState, value: any) => {
    this.setState({ ...this.state, [field]: value });
  };
}
