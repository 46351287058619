import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "../../../Configs/store/AppReducer";
import { ArtWorkListViewModel, ArtWorkListViewState } from "../../../Core/ViewModels/ArtWork/ArtWorkListViewModel";
import { ViewModelLocator } from "../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../Hooks/Lifecycle/useLifecycle";

export const useArtWorkListPageController = () => {
  const { sectionId } = useParams<{ sectionId: string }>();

  const categoriesState = useSelector((state: AppState) => state.Categories);

  const { vm, state } = useLifecycle<ArtWorkListViewState, ArtWorkListViewModel>({
    getRef: () => ViewModelLocator.ArtWorkListVM,
    setRefValue: (value) => (ViewModelLocator.ArtWorkListVM = value),
    newInstance: () => new ArtWorkListViewModel(sectionId, categoriesState),
    logName: "ArtWorkListVM",
  });

  const i18n = useIntl();

  return { vm, state, i18n, sectionId };
};
