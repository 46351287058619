import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { Header } from "../../Components/Views/Header/Header";
import { useArtWorkListPageController } from "./ArtWorkListPageController";
import "./ArtWorkListPage.scss";
import { ArtWorkListElement } from "./ArtWorkListElement";
import { CustomSearch } from "../../Components/Views/SearchInput/CustomSearch";
import Map from "../../assets/images/img_map.svg";
import { NewsDialog } from "../News/NewsDialog";

export interface ArtWorkListPageProps {}

const ArtWorkListPage = () => {
  const { vm, state, i18n, sectionId } = useArtWorkListPageController();

  return (
    <Header
      page={state.section?.type}
      backAction={() => getNavigationService().value().goBack()}
      renderCustomAction={<img src={Map} alt="map" />}
      customAction={() => getNavigationService().value().goToArtWorkMap(sectionId)}>
      {state.section?.news && <NewsDialog sectionNews={state.section.news} />}
      <div className="ArtWorkListPage-root">
        {/* <div className="ArtWorkListPage-title">{state.section?.name ?? state.sectionName}</div> */}
        {state.section?.image_url && (
          <img src={state.section?.image_url} alt="" className="ArtWorkListPage-header-image" />
        )}
        <div className="ArtWorkListPage-header-title">{state.section?.name}</div>
        <div className="ArtWorkListPage-actions">
          <CustomSearch
            inputValue={state.search}
            placeholder={i18n.formatMessage({ id: "COMMON_SEARCH" })}
            onChange={(value) => vm.onChangeField("search", value)}
            onSubmit={vm.onSearch}
          />
        </div>
        <div className="ArtWorkList-list">
          {state.items.map((item) => (
            <ArtWorkListElement key={item.id} item={item} sectionId={sectionId} />
          ))}
        </div>
      </div>
    </Header>
  );
};

export { ArtWorkListPage };
