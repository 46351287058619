import { fr } from "../../Locales/fr";
import { nl } from "../../Locales/nl";
import { SUPPORTED_LOCALES } from "./SetupIntl";

export class I18nLocaleConfig {
  public static getNavigatorLanguage = () => {
    const navigator = window.navigator;
    //* TO USE the full country codes, like : fr-BE, nl-BE
    const navigatorLang = navigator?.language ?? "";

    //* TO USE the short country code, like : fr, nl
    const language: string = navigatorLang.length >= 2 ? navigatorLang.slice(0, 2).toLowerCase() : "";
    return { navigatorLang, language };
  };

  public static getInitialLocale = () => {
    const { navigatorLang, language } = this.getNavigatorLanguage();

    if (SUPPORTED_LOCALES.includes(navigatorLang)) {
      return navigatorLang;
    } else if (SUPPORTED_LOCALES.includes(language)) {
      return language;
    }

    return SUPPORTED_LOCALES[0];
  };

  public static getLocaleMessages = (locale: string) => {
    switch (locale) {
      case "nl":
        return nl;
      default:
        return fr;
    }
  };

  public static setHTMLLang = (locale: string) => {
    document.documentElement.setAttribute("lang", locale);
  };
}
