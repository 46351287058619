import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  ArtWorkDetailsViewModel,
  ArtWorkDetailsViewState,
} from "../../../../Core/ViewModels/ArtWork/ArtWorkDetailsViewModel";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";

export const useArtWorkDetailsPageController = () => {
  const { sectionId, artworkId } = useParams<{ sectionId: string; artworkId: string }>();

  const { vm, state } = useLifecycle<ArtWorkDetailsViewState, ArtWorkDetailsViewModel>({
    getRef: () => ViewModelLocator.ArtWorkDetailsVM,
    setRefValue: (value) => (ViewModelLocator.ArtWorkDetailsVM = value),
    newInstance: () => new ArtWorkDetailsViewModel(sectionId, artworkId),
    logName: "ArtWorkDetailsVM",
  });

  const i18n = useIntl();

  return { vm, state, i18n, sectionId };
};
