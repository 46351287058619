import "./InformationsPage.scss";
import { Header } from "../../Components/Views/Header/Header";
import { ButtonBase } from "@material-ui/core";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { FormattedMessage } from "react-intl";
import ImgInterreg from "../../assets/images/img_interreg_tourism_lab.png";

const InformationsPage = () => {
  return (
    <Header page="ParcoursArty" backAction={getNavigationService().value().goBack}>
      <div className="InformationsPage-root">
        <div className="InformationsPage-mainButtons">
          <ButtonBase
            className="InformationsPage-button"
            onClick={getNavigationService().value().goToAbout}
            disableRipple
            disableTouchRipple>
            <FormattedMessage id="InformationsPage_About" />
          </ButtonBase>
          <ButtonBase
            className="InformationsPage-button"
            onClick={getNavigationService().value().goToLegalNotice}
            disableRipple
            disableTouchRipple>
            <FormattedMessage id="InformationsPage_LegalNotice" />
          </ButtonBase>
        </div>
        <div className="InformationsPage-footer">
          <p>Avec le soutien de</p>
          <img src={ImgInterreg} alt="interreg-logo" className="InformationsPage-footer-image" />
        </div>
      </div>
    </Header>
  );
};

export { InformationsPage };
