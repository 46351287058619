import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { ApiUrls } from "../Models/ApiUrls";
import { ArtWorkDetailsResponse, ArtWorkListResponse, ArtWorkMapListResponse } from "../Models/ArtWork/ArtWork";
import { CategoriesListResponse } from "../Models/Categories/Categories";
import { BaseDataService } from "./Base/BaseDataService";

export const ArtWorkDataServiceName: string = "ArtWorksDataService";

export interface IArtWorkDataService {
  getArtWorks: (
    sectionId: string,
    cts: XHRRequestCanceler,
    categoryIds: string[],
    search?: string
  ) => Promise<ArtWorkListResponse>;
  getCategories: (cts: XHRRequestCanceler) => Promise<CategoriesListResponse>;

  getArtWork: (artworkId: string, cts: XHRRequestCanceler) => Promise<ArtWorkDetailsResponse>;

  getArtWorksMap: (sectionId: string, cts: XHRRequestCanceler) => Promise<ArtWorkMapListResponse>;
}

export class ArtWorkDataService extends BaseDataService implements IArtWorkDataService {
  public getArtWorks(
    sectionId: string,
    cts: XHRRequestCanceler,
    categoryIds: string[],
    search?: string
  ): Promise<ArtWorkListResponse> {
    const params = {
      sectionId: sectionId,
      search: search,
      categoryIds: categoryIds,
    };

    return this.get<ArtWorkListResponse>(ApiUrls.ART_WORKS, cts, { params }, true);
  }

  public getCategories(cts: XHRRequestCanceler): Promise<CategoriesListResponse> {
    return this.get<CategoriesListResponse>(ApiUrls.ART_WORKS_CATEGORIES, cts, {}, true);
  }

  public getArtWork(artworkId: string, cts: XHRRequestCanceler): Promise<ArtWorkDetailsResponse> {
    return this.get<ArtWorkDetailsResponse>(ApiUrls.ART_WORK(artworkId), cts, {}, true);
  }

  public getArtWorksMap(sectionId: string, cts: XHRRequestCanceler): Promise<ArtWorkMapListResponse> {
    const params = {
      sectionId
    }
    return this.get<ArtWorkMapListResponse>(ApiUrls.ART_WORK_MAP, cts, {params}, true);
  }
}
