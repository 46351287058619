import "./Map.scss";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Constants } from "../../../../Core/Constants";
import { ArtWorkMap } from "../../../../Core/Models/ArtWork/ArtWork";
import IcMarkerCurrent from "../../../assets/icons/ic_marker_current.svg";
import IcMarkerCanal from "../../../assets/icons/ic_marker_canal.svg";
import IcMarkerMer from "../../../assets/icons/ic_marker_mer.svg";

export interface MapProps {
  markersType: string;
  markers: ArtWorkMap[];
  onClick: (artWorkId: string) => void;
  currentPosition?: GeolocationPosition;
}

const Map = ({ markersType, markers, onClick, currentPosition }: MapProps) => {
  const getIcon = (): string => {
    if (markersType === "ParcoursCanal") {
      return IcMarkerCanal
    } else {
      return IcMarkerMer
    }
  };

  const getBounds = (markers: ArtWorkMap[]): { maxLat: number; maxLng: number; minLat: number; minLng: number } => {
    let maxLat = 0;
    let maxLng = 0;
    let minLat = 90;
    let minLng = 180;

    markers.forEach((marker) => {
      if (marker.latitude > maxLat) {
        maxLat = marker.latitude;
      }
      if (marker.latitude < minLat) {
        minLat = marker.latitude;
      }

      if (marker.longitude > maxLng) {
        maxLng = marker.longitude;
      } 
      if (marker.longitude < minLng) {
        minLng = marker.longitude;
      }
    });
    return { maxLat, maxLng, minLat, minLng };
  };

  const containerStyle = {
    width: "100vw",
    height: "100vh",
  };

  return (
    <LoadScript googleMapsApiKey={Constants.getGoogleMapKey()}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={(map) => {
          const bounds = getBounds(markers);
          const mapBounds = new window.google.maps.LatLngBounds(
            { lat: bounds.minLat, lng: bounds.minLng },
            { lat: bounds.maxLat, lng: bounds.maxLng }
          );
          map.fitBounds(mapBounds, { bottom: 100, left: 100, right: 100, top: 100 });
        }}
        options={{
          disableDefaultUI: true,
          styles: [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off" }] }],
        }}>
        {markers.map((marker, index) => {
          return (
            //@ts-ignore
            <Marker
              key={index}
              position={{ lat: marker.latitude, lng: marker.longitude }}
              icon={getIcon(marker)}
              onClick={() => onClick(marker.id)}
            />
          );
        })}
        {currentPosition && (
          <Marker
            position={{ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude }}
            icon={IcMarkerCurrent}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export { Map };
