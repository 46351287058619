export class Constants {
  public static CLIENT_ID = "BACK_WEB ";
  public static CLIENT_SECRET = "back_web";
  private static API_BASE_URL = "__API_HOST__";
  private static API_BASE_URL_DEV = "https://interreg-api-dev.azurewebsites.net/";

  public static DEFAULT_ITEM_COUNT_PER_PAGE = 15;
  static DEFAULT_MAP_ZOOM = 16;

  static GOOGLE_MAP_API_KEY_DEV = "AIzaSyDa6oEFzlKlMuzC5MWLhQiGdBgQc8XCQms";
  static GOOGLE_MAP_API_KEY = "__GOOGLE_MAP_API_KEY__";

  public static isDev = process.env.NODE_ENV === "development";

  public static getRoot = (): string => (Constants.isDev ? Constants.API_BASE_URL_DEV : Constants.API_BASE_URL);

  static getGoogleMapKey = () => (Constants.isDev ? Constants.GOOGLE_MAP_API_KEY_DEV : Constants.GOOGLE_MAP_API_KEY);
}
