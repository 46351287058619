import { FormattedMessage } from "react-intl";
import { Section } from "../../../Core/Models/Section/Section";
import { SectionListViewState } from "../../../Core/ViewModels/Section/SectionListViewModel";
import ImgParcoursArty from "../../assets/images/img_parcours_arty_logo.png";
import DownloadAndroid from "../../assets/images/img_download_android.png";
import DownloadIos from "../../assets/images/img_download_ios.png";
import { CustomDialog } from "../../Components/Views/CustomDialogs/CustomDialog/CustomDialog";
import { NewsDialog } from "../News/NewsDialog";
import { SectionList } from "./SectionList";
import "./SectionListPage.scss";
import { useSectionListPageController } from "./SectionListPageController";

export interface SectionListPageProps {}

export const useMainSectionWithOthers = (state: SectionListViewState) => {
  let mainSection: Section[] = [];

  for (var s of state.items) {
    mainSection.unshift(s);
  }

  return { mainSection };
};

const SectionListPage = () => {
  const { state, vm, platform } = useSectionListPageController();
  const { mainSection } = useMainSectionWithOthers(state);

  const getPlatform = () => {
    if (platform === "Android" || platform.startsWith("Linux")) {
      return "Android";
    } else if (platform === "iPhone" || platform === "iPod" || platform === "iPad") {
      return "iOS";
    } else {
      return "other";
    }
  };

  return (
    <div className="SectionListPage-root">
      {
        <CustomDialog
          open={state.showDialog}
          onClose={vm.onCloseDialog}
          renderActions={() => ""}
          classes={{ paper: "SectionListPage-Dialog-paper" }}>
          <div className="SectionListPage-dialogContent-root">
            <div className="SectionListPage-dialogContent-helpText">
              <FormattedMessage id="DownloadApp_Dialog_HelpText" />
            </div>
            <a
              className="SectionListPage-dialogContent-downloadLink"
              href={
                getPlatform() === "Android"
                  ? "https://play.google.com/store/apps/details?id=fr.conditionpublique.app"
                  : "https://apps.apple.com/fr/app/la-condition-publique/id1591062113"
              }>
              {(getPlatform() === "Android" || getPlatform() === "other") && (
                <img src={DownloadAndroid} alt="download android" />
              )}
              {(getPlatform() === "iOS" || getPlatform() === "other") && <img src={DownloadIos} alt="download ios" />}
            </a>
          </div>
        </CustomDialog>
      }
      {!state.showDialog && <NewsDialog />}
      <div className="SectionListPage-header">
        <img src={ImgParcoursArty} alt="logo" className="SectionListPage-logo" />
      </div>
      <SectionList items={mainSection} />
    </div>
  );
};

export { SectionListPage };
