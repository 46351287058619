import { addExtraLocaleData } from "./Utils/addExtraLocaleData";

//🇫🇷 🇳🇱
const SUPPORTED_LOCALES = ["fr", "nl"];

const setupIntl = (): Promise<boolean> => {
  return addExtraLocaleData();
};

export { setupIntl, SUPPORTED_LOCALES };
