import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { ISectionDataService, SectionDataServiceName } from "../../DataServices/SectionDataService";
import { Section } from "../../Models/Section/Section";
import { IoC } from "../../Services/ServicesContainer";
import { BaseAppViewModel } from "../Base/BaseAppViewModel";
import { BaseViewState } from "../Base/BaseViewModel";

export interface SectionListViewState extends BaseViewState {
  items: Section[];
  showDialog: boolean;
}

const getInitialSectionListViewState = (): SectionListViewState => ({
  items: [],
  showDialog: (() => {
    //Check for session cookies.
    if (document.cookie.includes("lastVisit")) {
      return false;
    }

    document.cookie = `lastVisit=${new Date()}`;
    return true;
  })(),
});

export class SectionListViewModel extends BaseAppViewModel<SectionListViewState> {
  private readonly SectionDataService: ISectionDataService;

  constructor() {
    super(getInitialSectionListViewState());
    this.SectionDataService = IoC.get<ISectionDataService>(SectionDataServiceName);
    this.getItems();
  }

  protected getItems() {
    this.WrapError(async (cts: XHRRequestCanceler) => {
      const response = await this.SectionDataService.getSections(cts);
      this.setState({ ...this.state, items: response.data });
    });
  }

  public onCloseDialog = () => {
    this.setState({ ...this.state, showDialog: false });
  };
}
