export const fr = {
  //* COMMONS
  COMMON_CANCEL: "Annuler",
  COMMON_VALIDATE: "Valider",
  COMMON_SEARCH: "Rechercher",
  COMMON_SEARCH_PLACEHOLDER: "Rechercher",
  COMMON_CONFIRM: "Confirmer",
  COMMON_NOT_SAVED: "Des données modifiées seront perdues si vous quittez sans enregistrer, voulez vous continuer ?",

  ArtWorkDetailsPage_Country: "Pays: {value}",

  //* Informations Page
  InformationsPage_About: "À propos",
  InformationsPage_LegalNotice: "Mentions légales",

  //* About Page
  AboutPage_Title: "À propos",
  AboutPage_Text1:
    "La Wallonie picarde, la Flandre occidentale et les Hauts-de-France s'associent pour créer un laboratoire transfrontalier d'expérimentations et d'innovations touristiques. L'objectif : développer une offre qui réponde aux envies actuelles des voyageurs en quête d'expériences touristiques.",
  AboutPage_Text2:
    "Plus de nature, plus d'écologie, plus de sensations, plus d'expériences virtuelles, plus de surprises, ... Le touriste d'aujourd'hui est un contemporain connecté à la recherche de nouvelles expériences. Il veut faire appel à tous ses sens et ramener dans sa valise des souvenirs inoubliables qui vont bien au-delà de l'album photo traditionnel. Le touriste veut jouer un rôle. Il est donc essentiel de répondre à ces nouveaux besoins et habitudes des voyageurs et de développer des offres pour eux si l'on veut stimuler les performances de l'économie touristique. Avec ce consommateur exigeant qui attend des solutions personnalisées, le tourisme de masse standardisé cède la place au tourisme sur mesure, et l'expérience touristique prend une place prépondérante dans les réflexions.",
  AboutPage_Text3:
    "Partant de ce constat, l'Agence de développement territorial IDETA et la Maison du tourisme de la Wallonie picarde (partenaires principaux du projet), Westtoer et La Tangente (partenaires transfrontaliers actifs dans le développement économique et touristique) s'associent dans le cadre d'un projet européen Interreg V France-Wallonie-Vlaanderen : « Tourism Lab ».",
  AboutPage_Text4:
    "Concrètement, la route Arty est une invitation à la découverte d'œuvres d'art (de type street art, interventions artistiques, land art...) dans la zone frontalière. La route des canaux relie Roubaix à Tournai et vous emmène le long du canal de Roubaix et du RAVel le long de l'Escaut. Ne manquez pas de lever les yeux et d'admirer les œuvres monumentales qui jalonnent votre parcours jusqu'à Léaucourt. La Route du littoral rassemble toutes les œuvres existantes dans l'espace public le long de la côte franco-belge. Téléchargez l'application « Trajectoire Arty » et découvrez toutes les œuvres : il y en a plus de 50 !",

  AboutPage_Text5: "> Les partenaires du projet :",
  AboutPage_Text5_1: "• Côté wallon : IDETA et la Maison du Tourisme de la Wallonie picarde",
  AboutPage_Text5_2: "• Côté flamand : Westtoer",
  AboutPage_Text5_3: "• Côté français : La Tangente et La Condition Publique",
  AboutPage_Text6: "Informations :\n",
  AboutPage_Text6_Link: "www.tourismlab.eu",
  AboutPage_Text6_HttpLink: "https://www.tourismlab.eu/",

  //* Legal notice Page
  LegalNoticePage_CGU: "CGU",
  LegalNoticePage_Licences: "Licences",

  //* CGU
  CGUPage_Text_Intro:
    "L'application « Parcours Arty » est publiée par l'EPCC de La Condition Publique. Elle a été réalisée avec le soutien du Fonds Européen de Développement Régional, dans le cadre du projet INTERREG TOURISM LAB.",
  CGUPage_Text_Editeur_Title: "INFORMATION ÉDITEUR",
  CGUPage_Text_Editeur_Content_1: "L’application « La Condition Publique » est éditée par l’EPCC La Condition Publique",
  CGUPage_Text_Editeur_Content_2: "Adresse : 14 place Faidherbe - 59100 Roubaix Tél : +33 (0)3 28 33 48 33",
  CGUPage_Text_Real_Title: "RÉALISATION",
  CGUPage_Text_Real_Content_1: "Cette application est développée par Idéine https://www.ideine.fr/",
  CGUPage_Text_Real_Content_2: "V1 – Octobre 2022",
  CGUPage_Text_Hosting_Title: "HÉBERGEMENT",
  CGUPage_Text_Hosting_Content: "OVH 2 rue Kellermann - 59100 Roubaix - France",
  CGUPage_Text_IntelProp_Title: "PROPRIÉTÉ INTELLECTUELLE",
  CGUPage_Text_IntelProp_Content:
    "Conformément au code de la Propriété Intellectuelle et plus généralement à tous les accords comportant des dispositions relatives au droit d'auteur, la reproduction partielle ou totale de textes, d'images ou d'illustrations non destinées explicitement à être téléchargées par les visiteurs, sont interdites sans autorisation préalable de l'éditeur ou de tout ayant-droit.",
  CGUPage_Text_Hyperlink_Title: "HYPERLIENS",
  CGUPage_Text_Hyperlink_Content:
    "L’application ‘Parcours Arty’ peut contenir des liens hypertextes menant à d'autres sites Internet totalement indépendants de l'application. La Condition publique, éditrice de l’application, ne suppose aucunement, ni ne garantit que les informations contenues dans de tels liens hypertextes ou dans tout autre site Internet soient exactes, complètes ou véridiques. Dès lors, tout accès à un autre site Internet s'effectue sous la propre responsabilité, pleine et entière, de l'utilisateur.",
  CGUPage_Text_Resp_Title: "RESPONSABILITÉS",
  CGUPage_Text_Resp_Content:
    "L'utilisation des informations contenues sur la présente application relève de la seule responsabilité de son utilisateur. Toutes les ressources contenues dans ce site, textes, visuels et illustrations (schémas, dessins, plans, photographies et animations informatiques) sont communiqués à titre purement informatif et ne peuvent en rien engager la responsabilité de l'éditeur. En effet, malgré le souhait de l'éditeur d’apporter les informations les plus exactes possibles et d’assurer une mise à jour régulière de l’application, celui–ci n’est pas responsable d’imprécisions, d’inexactitudes, d’erreurs ou de possibles omissions portant sur des informations contenues sur le site ni des résultats obtenus par l’utilisation et la pratique des informations délivrées sur ce support de communication.",
  CGUPage_Text_AccessRight_Title: "EXERCICE DU DROIT D'ACCÈS",
  CGUPage_Text_AccessRight_Content:
    'Conformément à l’article 34 de la loi "Informatique et Libertés", vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant. Pour exercer ce droit d’accès, adressez–vous à l’éditeur. Pour plus d’informations sur la loi « Informatique et Libertés », vous pouvez consulter le site Internet de la CNIL.',

  //* DownloadApp Dialog
  DownloadApp_Dialog_HelpText: "Pour une meilleure expérience, n’hésitez pas à télécharger notre application mobile.",

  //* Home
  HomeInfosButton_Text: "Info",

  SectionTitle_HighlightWord: "¤",
};
