import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { IArtWorkDataService, ArtWorkDataServiceName } from "../../DataServices/ArtWorkDataService";
import { ArtWork } from "../../Models/ArtWork/ArtWork";
import { Section } from "../../Models/Section/Section";
import { CategoriesState } from "../../Services/Categories/typings/CategoriesState";
import { IoC } from "../../Services/ServicesContainer";
import { BaseAppViewModel } from "../Base/BaseAppViewModel";
import { BaseViewState } from "../Base/BaseViewModel";

export interface ArtWorkListViewState extends BaseViewState {
  items: ArtWork[];
  sectionName: string;
  search: string;
  categoriesNb: number;
  section: Section | undefined;
}

const InitialArtWorkListViewState: ArtWorkListViewState = {
  items: [],
  sectionName: "",
  section: undefined,
  search: "",
  categoriesNb: 0,
};

export class ArtWorkListViewModel extends BaseAppViewModel<ArtWorkListViewState> {
  private readonly ArtWorkDataService: IArtWorkDataService;

  constructor(private readonly sectionId: string, private readonly categoriesState: CategoriesState | undefined) {
    super(InitialArtWorkListViewState);
    this.ArtWorkDataService = IoC.get<IArtWorkDataService>(ArtWorkDataServiceName);
    this.getItems();
  }

  protected getItems = () => {
    this.WrapError(async (cts: XHRRequestCanceler) => {
      const response = await this.ArtWorkDataService.getArtWorks(
        this.sectionId,
        cts,
        this.categoriesState?.selectedList || [],
        this.state.search
      );
      const sectionName = (response.data && response.data.length !== 0 && response.data[0].section.name) || "";

      this.setState({
        ...this.state,
        items: response.data,
        sectionName: sectionName,
        section: response.section,
        categoriesNb: this.categoriesState?.selectedList.length || 0,
      });
    });
  };

  public onChangeField = (field: keyof ArtWorkListViewState, value: any) => {
    this.setState({ ...this.state, [field]: value });
  };

  public onSearch = () => {
    this.getItems();
  };
}
