import { useState } from "react";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import NoImageMer from "../../assets/images/img_mer_no_image.svg";
import NoImageCanal from "../../assets/images/img_canal_no_image.svg";
import { ArtWork } from "../../../Core/Models/ArtWork/ArtWork";
import { Parcours } from "../../../Core/Models/Section/Section";

export type ArtWorkProps = {
  item: ArtWork;
  sectionId: string;
};

export type ArtWorkImageProp = {
  image_url: string;
  section: Parcours;
};

const ArtWorkListElement = ({ item, sectionId }: ArtWorkProps) => {
  const textLength = item.artist_name.length + item.title.length;
  return (
    <div
      className={
        item.section.type === "ParcoursMer" ? "ArtWorkList-element ParcoursMer" : "ArtWorkList-element ParcoursCanal"
      }
      onClick={() => getNavigationService().value().goToArtWorkDetails(sectionId, item.id)}>
      <ArtWorkListElementImage image_url={item.image_url} section={item.section.type as Parcours} />

      <div className="ArtWorkList-elementDetails" style={{ fontSize: textLength > 80 ? textLength > 162 ? "12px" : "15px" : "18px" }}>
        <div className="ArtWorkList-elementAuthor">{item.artist_name}</div>
        <div className="ArtWorkList-elementTitle">{item.title}</div>
      </div>
    </div>
  );
};

export { ArtWorkListElement };

const ArtWorkListElementImage = ({ image_url, section }: ArtWorkImageProp) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="ArtWorkList-elementImage">
      {image_url && (
        <img
          src={image_url}
          alt=""
          className="ArtWorkList-urlImage"
          onLoad={() => {
            setIsLoaded(true);
          }}
          style={isLoaded ? { opacity: 1 } : { opacity: 0 }}
        />
      )}
      {section === "ParcoursMer" ? (
        <img src={NoImageMer} alt="artwork" className="ArtWorkList-fallbackImage" />
      ) : (
        <img src={NoImageCanal} alt="artwork" className="ArtWorkList-fallbackImage" />
      )}
    </div>
  );
};
