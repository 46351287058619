import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { ArtWorkDataServiceName, IArtWorkDataService } from "../../DataServices/ArtWorkDataService";
import { ArtWorkMap, ArtWorkMapListResponse } from "../../Models/ArtWork/ArtWork";
import { Parcours } from "../../Models/Section/Section";
import { IoC } from "../../Services/ServicesContainer";
import { BaseAppViewModel } from "../Base/BaseAppViewModel";
import { BaseViewState } from "../Base/BaseViewModel";

export interface ArtWorkMapViewState extends BaseViewState {
  sectionType: Parcours;
  items: ArtWorkMap[];
  currentPosition?: GeolocationPosition;
}

const InitialArtWorkMapViewState: ArtWorkMapViewState = {
  sectionType: "ParcoursCanal",
  items: [],
  currentPosition: undefined,
};

export class ArtWorkMapViewModel extends BaseAppViewModel<ArtWorkMapViewState> {
  private readonly ArtWorkDataService: IArtWorkDataService;

  constructor(private readonly sectionId: string) {
    super(InitialArtWorkMapViewState);
    this.ArtWorkDataService = IoC.get<IArtWorkDataService>(ArtWorkDataServiceName);
    this.getItems();
  }

  protected getItems = () => {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        const response: ArtWorkMapListResponse = await this.ArtWorkDataService.getArtWorksMap(this.sectionId, cts);
        const responseType = await this.ArtWorkDataService.getArtWork(response.data[0].id, cts);
        navigator.geolocation.getCurrentPosition((position) => {
          this.setState({ ...this.state, currentPosition: position });
        });
        this.setState({ ...this.state, items: response.data, sectionType: responseType.data.section.type as Parcours });
      },
      { withBusy: true }
    );
  };
}
