import { ArtWorkDetailsViewModel } from "./ArtWork/ArtWorkDetailsViewModel";
import { ArtWorkFilterViewModel } from "./ArtWork/ArtWorkFilterViewModel";
import { ArtWorkListViewModel } from "./ArtWork/ArtWorkListViewModel";
import { ArtWorkMapViewModel } from "./ArtWork/ArtWorkMapViewModel";
import { SectionListViewModel } from "./Section/SectionListViewModel";

export class ViewModelLocator {
  public static SectionListVM: SectionListViewModel | null = null;
  public static ArtWorkListVM: ArtWorkListViewModel | null = null;
  public static ArtWorkFilterVM: ArtWorkFilterViewModel | null = null;
  public static ArtWorkDetailsVM: ArtWorkDetailsViewModel | null = null;
  public static ArtWorkMapVM: ArtWorkMapViewModel | null = null;
}
