import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { ArtWorkMapViewModel, ArtWorkMapViewState } from "../../../../Core/ViewModels/ArtWork/ArtWorkMapViewModel";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";

export const useArtWorkMapPageController = () => {
  const { sectionId } = useParams<{ sectionId: string }>();

  const { vm, state } = useLifecycle<ArtWorkMapViewState, ArtWorkMapViewModel>({
    getRef: () => ViewModelLocator.ArtWorkMapVM,
    setRefValue: (value) => (ViewModelLocator.ArtWorkMapVM = value),
    newInstance: () => new ArtWorkMapViewModel(sectionId),
    logName: "ArtWorkMapVM",
  });

  const i18n = useIntl();

  return { vm, state, i18n, sectionId };
};
