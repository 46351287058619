import { I18nLocaleConfig } from "./i18nLocaleConfig";
import { SUPPORTED_LOCALES } from "./SetupIntl";
import { LocaleMessageState } from "./typings/LocaleMessageState";
import * as LocalMessageActionTypes from "./typings/LocalMessageActionTypes";

const locale = I18nLocaleConfig.getInitialLocale();

const initialState: LocaleMessageState = {
  defaultLocale: locale,
  locale: locale,
  supported_locales: SUPPORTED_LOCALES,
  messages: { ...I18nLocaleConfig.getLocaleMessages(locale) },
};

console.log("Initial", initialState);

const I18nReducer = (state = initialState, action: LocalMessageActionTypes.LocalMessageAction) => {
  switch (action.type) {
    case LocalMessageActionTypes.LocalMessageActionType.SET_LOCALE_WITH_MESSAGES:
      if (state.supported_locales.indexOf(action.payload.locale) !== -1) {
        return { ...state, ...action.payload };
      }
      return state;
    case LocalMessageActionTypes.LocalMessageActionType.ADD_MESSAGES:
      return {
        ...state,
        messages: { ...state.messages, ...action.payload.messages },
      };
    default:
      return state;
  }
};

export { I18nReducer };
