import "./LegalNoticePage.scss";
import { Header } from "../../../Components/Views/Header/Header";
import { ButtonBase } from "@material-ui/core";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import { FormattedMessage } from "react-intl";

const LegalNoticePage = () => {
  return (
    <Header page="ParcoursArty" backAction={getNavigationService().value().goBack}>
      <div className="LegalNoticePage-root">
        <div className="LegalNoticePage-buttons">
          <ButtonBase className="LegalNoticePage-button" onClick={getNavigationService().value().goToCGU} disableRipple disableTouchRipple>
            <FormattedMessage id="LegalNoticePage_CGU" />
          </ButtonBase>
          <ButtonBase className="LegalNoticePage-button" onClick={getNavigationService().value().goToLicences} disableRipple disableTouchRipple>
            <FormattedMessage id="LegalNoticePage_Licences" />
          </ButtonBase>
        </div>
      </div>
    </Header>
  );
};

export { LegalNoticePage };
