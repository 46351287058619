export const nl = {
  //* COMMONS
  COMMON_CANCEL: "Annuleren",
  COMMON_VALIDATE: "Valideren",
  COMMON_SEARCH: "Zoeken",
  COMMON_SEARCH_PLACEHOLDER: "Zoeken",
  COMMON_CONFIRM: "Bevestigen",
  COMMON_NOT_SAVED:
    "Gewijzigde gegevens gaan verloren indien u de pagina verlaat zonder ze eerst op te slaan; wenst u verder te gaan? ",

  ArtWorkDetailsPage_Country: "Land: {value}",

  //* Informations Page
  InformationsPage_About: "Over",
  InformationsPage_LegalNotice: "Wettelijke bepalingen",

  //* About Page
  AboutPage_Title: "Over",
  AboutPage_Text1:
    "Picardisch Wallonië, West-Vlaanderen en Hauts-de-France slaan de handen in elkaar voor de oprichting van een grensoverschrijdend laboratorium voor experimenten en innovaties op het vlak van toerisme. Doel: een aanbod ontwikkelen dat inspeelt op de huidige verlangens van reizigers die naar toeristische ervaringen snakken.",
  AboutPage_Text2:
    "Meer natuur, meer ecologie, meer sensatie, meer virtuele belevingen, meer verrassingen, ... De toerist van vandaag is een connected hedendaags mens die op zoek is naar nieuwe ervaringen. Hij wil zijn zintuigen ten volle benutten en in zijn koffer onvergetelijke herinneringen mee naar huis nemen die veel verder gaan dan het traditionele fotoalbum. De toerist wil een rol spelen. Het is dan ook essentieel om op die nieuwe behoeften en gewoonten van de reizigers in te gaan en voor hen een aanbod te ontwikkelen, willen we de prestaties van de toeristische economie een boost geven. Nu deze veeleisende consument een personaliseerbare oplossing verwacht, maakt het gestandaardiseerde massatoerisme plaats voor toerisme op maat, en de toeristische beleving krijgt een centrale plek in de beschouwingen.",
  AboutPage_Text3:
    "Op basis van die vaststelling gaan het Agentschap voor Territoriale ontwikkeling IDETA en het Maison du tourisme de la Wallonie picarde (hoofdpartners van het project), Westtoer en La Tangente (grensoverschrijdende partners die actief zijn in de economische en toeristische ontwikkeling) samen in zee voor een Europees Interreg V-project France-Wallonie-Vlaanderen: “Tourism Lab”.",
  AboutPage_Text4:
    "Concreet is het traject Arty een uitnodiging om in het grensgebied kunstwerken te ontdekken (van het type street art, artistieke interventies, land art, ...). Het traject Canal verbindt Roubaix met Doornik en neemt u mee langs het Kanaal van Roubaix en de RAVel langs de Schelde. Kijk zeker ook eens naar boven en bewonder de monumentale werken langs uw parcours naar Léaucourt. Het traject Kust brengt alle bestaande werken samen in de publieke ruimte langs de Frans-Belgische kust. Download de app “Traject Arty” en kom alles te weten over de werken: er zijn er meer dan vijftig!",
  AboutPage_Text5: "> De projectpartners:",
  AboutPage_Text5_1: "• Waalse zijde: IDETA en het Maison du Tourisme de la Wallonie picarde",
  AboutPage_Text5_2: "• Vlaamse zijde: Westtoer",
  AboutPage_Text5_3: "• Franse zijde: La Tangente en La Condition Publique",
  AboutPage_Text6: "Informatie:\n",
  AboutPage_Text6_Link: "www.tourismlab.eu",
  AboutPage_Text6_HttpLink: "https://www.tourismlab.eu/",

  //* Legal notice Page
  LegalNoticePage_CGU: "AGV ",
  LegalNoticePage_Licences: "Licenties",

  //* CGU
  CGUPage_Text_Intro:
    "De app “Parcours Arty” wordt uitgegeven door het EPCC van La Condition Publique. Ze werd gerealiseerd met de steun van het Europees Fonds voor Regionale Ontwikkeling, in het kader van het INTERREG-project TOURISM LAB.",
  CGUPage_Text_Editeur_Title: "INFORMATIE UITGEVER ",
  CGUPage_Text_Editeur_Content_1:
    "De applicatie van “La Condition Publique” wordt uitgegeven door het EPCC van La Condition Publique. ",
  CGUPage_Text_Editeur_Content_2: "Adres: 14 place Faidherbe - 59100 Roubaix Tel : +33 (0)3 28 33 48 33",
  CGUPage_Text_Real_Title: "REALISATIE",
  CGUPage_Text_Real_Content_1: "Agence IDEINE https://www.ideine.fr/",
  CGUPage_Text_Real_Content_2: "V1 – Oktober 2021",
  CGUPage_Text_Hosting_Title: "HOSTING",
  CGUPage_Text_Hosting_Content: "OVH 2 rue Kellermann - 59100 Roubaix - Frankrijk",
  CGUPage_Text_IntelProp_Title: "INTELLECTUELE EIGENDOM",
  CGUPage_Text_IntelProp_Content:
    "In overeenstemming met het wetboek Intellectuele Eigendom en in het algemeen met alle overeenkomsten die bepalingen bevatten aangaande de auteursrechten, is het verboden om zonder voorafgaande toestemming van de uitgever of om het even welke rechthebbende, teksten, beelden of illustraties die niet expliciet bedoeld zijn om door gebruikers gedownload te worden, geheel of gedeeltelijk te reproduceren. ",
  CGUPage_Text_Hyperlink_Title: "HYPERLINKS",
  CGUPage_Text_Hyperlink_Content:
    "De applicatie ‘La Condition Publique’ kan hyperlinks bevatten naar andere websites die volledig losstaan van de website www.laconditionpublique.com. In geen geval veronderstelt noch waarborgt La Condition Publique dat de informatie op dergelijke hyperlinks of andere websites exact, volledig en waarheidsgetrouw is.  Derhalve is de gebruiker volledig en exclusief aansprakelijk voor elke toegang tot een andere website die aan de website www.laconditionpublique.com gelinkt is. ",
  CGUPage_Text_Resp_Title: "VERANTWOORDELIJKHEDEN",
  CGUPage_Text_Resp_Content:
    "Alleen de gebruiker is aansprakelijkheid voor de manier waarop hij de informatie op deze applicatie aanwendt.  Alle werkmiddelen die op deze website staan, de teksten, het beeldmateriaal en de illustraties (schema’s, tekeningen, plannen, foto’s en digitale animaties) worden louter ter informatie weergegeven en vallen niet onder de verantwoordelijkheid van de uitgever.  Ondanks het feit dat de uitgever zo exact mogelijke informatie wil verstrekken en de applicatie regelmatig updatet, is hij immers niet verantwoordelijk voor onnauwkeurigheden, onjuistheden, fouten of mogelijke weglatingen met betrekking tot informatie die op de website staat, noch voor de resultaten die worden bekomen door het gebruik en de toepassing van de op deze communicatiedrager verstrekte informatie. ",
  CGUPage_Text_AccessRight_Title: "UITOEFENING VAN HET TOEGANGSRECHT ",
  CGUPage_Text_AccessRight_Content:
    "In overeenstemming met artikel 34 van de wet “Informatica en vrijheden” hebt u het recht om toegang te verschaffen tot de gegevens die op u betrekking hebben, om ze te wijzigen, te verbeteren of te verwijderen.  Om dit recht te doen gelden moet u zich tot de uitgever richten.  Voor meer informatie over de wet “Informatica en vrijheden” kunt u de website van het Inspectoraat voor gegevensbescherming raadplegen. ",

  //* DownloadApp Dialog
  DownloadApp_Dialog_HelpText: "Voor een nog betere ervaring kunt u onze mobiele applicatie downloaden. ",

  //* Home
  HomeInfosButton_Text: "Info",

  SectionTitle_HighlightWord: "¤",
};
