import { IconButton } from "@material-ui/core";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import { Header } from "../../../Components/Views/Header/Header";
import { Icons } from "../../../Components/Views/Icons/Icons";
import "./ArtWorkDetailsPage.scss";
import { useArtWorkDetailsPageController } from "./ArtWorkDetailsPageController";
import NoImageMer from "../../../assets/images/img_artwork_mer_no_image.svg";
import NoImageCanal from "../../../assets/images/img_artwork_canal_no_image.svg";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { Parcours } from "../../../../Core/Models/Section/Section";
import { RWebShare } from "react-web-share";

const ArtWorkDetailsPage = () => {
  const { state, vm } = useArtWorkDetailsPageController();
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Header page={state.item.section.type as Parcours} backAction={() => getNavigationService().value().goBack()}>
      <div className="ArtWorkDetailsPage-root">
        <div className="ArtWorkDetailsPage-picture">
          {state.item.picture_url && (
            <img
              src={state.item.picture_url}
              className="ArtWorkDetailsPage-urlImage"
              alt="artwork"
              onLoad={() => {
                setIsLoaded(true);
              }}
              style={isLoaded ? { opacity: 1 } : { opacity: 0 }}
            />
          )}
          {state.item.section.type === "ParcoursMer" ? (
            <img src={NoImageMer} alt="empty" className="ArtWorkDetailsPage-fallbackImage" />
          ) : (
            <img src={NoImageCanal} alt="empty" className="ArtWorkDetailsPage-fallbackImage" />
          )}
        </div>
        <div className="ArtWorkDetailsPage-infos">
          <div className="ArtWorkDetailsPage-header">
            <div className="ArtWorkDetailsPage-header-infos">
              <div className="ArtWorkDetailsPage-title">{state.item.title}</div>
              <div className="ArtWorkDetailsPage-artist">{state.item.artist_name}</div>
              <div className="ArtWorkDetailsPage-subtitle">
                {state.item.subtitle} {state.item.subtitle && state.item.year ? "-" : ""} {state.item.year}
              </div>
              {state.item.country && (
                <div className="ArtWorkDetailsPage-country">
                  <FormattedMessage id="ArtWorkDetailsPage_Country" values={{ value: state.item.country }} />
                </div>
              )}
            </div>
            <div className="ArtWorkDetailsPage-actions">
              <RWebShare
                data={{
                  text: `'${state.item.title}' sur Parcours Arty`,
                  url: state.item.app_url,
                  title: state.item.title,
                }}>
                <IconButton>{Icons.Share}</IconButton>
              </RWebShare>
            </div>
          </div>

          <div className="ArtWorkDetailsPage-shortDescription">{state.item.short_description}</div>

          <div className="ArtWorkDetailsPage-border" />
          <div
            className="ArtWorkDetailsPage-description"
            dangerouslySetInnerHTML={{ __html: state.item.description }}
          />
          <div className="ArtWorkDetailsPage-copyrights">{state.item.copyrights}</div>
        </div>
      </div>
    </Header>
  );
};

export { ArtWorkDetailsPage };
