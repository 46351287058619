import { SUPPORTED_LOCALES } from "../../Core/Services/I18n/SetupIntl";
import { I18nLocaleConfig } from "../../Core/Services/I18n/i18nLocaleConfig";
import { LocaleMessageState } from "../../Core/Services/I18n/typings/LocaleMessageState";

const locale = I18nLocaleConfig.getInitialLocale();

const I18n: LocaleMessageState = {
  defaultLocale: locale,
  locale: locale,
  supported_locales: SUPPORTED_LOCALES,
  messages: { ...I18nLocaleConfig.getLocaleMessages(locale) },
};

export const InitialState = { I18n };
